import React from 'react';

const ModalLoading = () => {
  const center = {
    top: '50%',
    left: '50%',
  };

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    // <div className={'absolute min-h-screen min-w-full bg-gray-500 bg-opacity-75 z-50 '}>
    <>
      <div
        className={
          'fixed inset-0 flex justify-center items-center overflow-hidden transform transition-all w-full z-50'
        }>
        <div className={'bg-white w-10/12 sm:w-6/12 p-5 rounded-3xl shadow-xl animate-pulse'}>
          <div className={'mx-5 text-center'}>
            {/* <div className={'mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'}> */}
            <h1 className={'my-6 text-2xl leading-6 font-bold text-gray-900'} id="modal-title">
              LOADING
            </h1>

            <h3 className={'my-6 text-lg leading-6 font-semibold text-gray-500'}>
              Please wait. Thank you.
            </h3>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className={'absolute min-h-screen min-w-full bg-gray-500 bg-opacity-75 z-40 '}></div>
    </>
    // </div>
  );
};

export default ModalLoading;
