import React from 'react';

// interface Props {
//   buttonType: 'close'|'cancel'|'submit',
//   className: React.HTMLAttributes<HTMLDivElement>,
//   isDisabled: boolean = false,
//   name: string,
//   onTap: () => void,
// }

const LongButton = (props) => {
  const { buttonType, className, isDisabled = false, name, onTap } = props;

  const getBgColor = () => {
    if (isDisabled) return 'cursor-not-allowed bg-gray-400';

    switch (buttonType) {
      case 'close':
        return 'cursor-pointer bg-red-400 hover:bg-red-600';
      case 'cancel':
        return 'cursor-pointer bg-yellow-400 hover:bg-yellow-600';
      case 'submit':
        return 'cursor-pointer bg-appColor1 hover:bg-hover1';
      default:
        return 'cursor-pointer bg-blue-400 hover:bg-blue-600';
    }
  };

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <button
      className={`flex flex-row justify-center items-center text-white py-3 px-8 rounded-lg shadow-md text-sm font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-appColor1 ${getBgColor()} ${className}`}
      name={name}
      onClick={isDisabled ? undefined : onTap}>
      {props.children}
    </button>
  );
};

export default LongButton;
