import { STORE_ACCESS_TOKEN } from '../actions/app';

const initialState = {
  access_token: '',
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_ACCESS_TOKEN:
      return { ...state, access_token: action.access_token };
    default:
      return state;
  }
};
export default appReducer;
