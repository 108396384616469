/**
 * constant string: for reducer switch case, avoid typo
 * constant function: for screen dispatch to invoke directly
 */

export const STORE_IS_LOGIN = 'STORE_IS_LOGIN';
export const STORE_IS_LOADING = 'STORE_IS_LOADING';
export const STORE_IS_ALERT = 'STORE_IS_ALERT';
export const STORE_IS_FLOATINGMENU_HIDDEN = 'STORE_IS_FLOATINGMENU_HIDDEN';
export const STORE_IS_DRAWER_HIDDEN = 'STORE_IS_DRAWER_HIDDEN';
export const STORE_USERID = 'STORE_USERID';
export const STORE_USERNAME = 'STORE_USERNAME';
export const STORE_FULLNAME = 'STORE_FULLNAME';
export const STORE_PERMISSIONS = 'STORE_PERMISSIONS';

export const storeIsLogin = (is_login) => {
  return { type: STORE_IS_LOGIN, is_login: is_login };
};
export const storeIsLoading = (is_loading) => {
  return { type: STORE_IS_LOADING, is_loading: is_loading };
};
export const storeIsAlert = (is_alert) => {
  return { type: STORE_IS_ALERT, is_alert: is_alert };
};
export const storeIsFloatingMenuHidden = (is_floatingmenu_hidden) => {
  return { type: STORE_IS_FLOATINGMENU_HIDDEN, is_floatingmenu_hidden: is_floatingmenu_hidden };
};
export const storeIsDrawerHidden = (is_drawer_hidden) => {
  return { type: STORE_IS_DRAWER_HIDDEN, is_drawer_hidden: is_drawer_hidden };
};
export const storeUserId = (userid) => {
  return { type: STORE_USERID, userid: userid };
};
export const storeUsername = (username) => {
  return { type: STORE_USERNAME, username: username };
};
export const storeFullname = (fullname) => {
  return { type: STORE_FULLNAME, fullname: fullname };
};
export const storePermissions = (permissions) => {
  return { type: STORE_PERMISSIONS, permissions: permissions };
};
