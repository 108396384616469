export const validateEmail = (txt) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(txt);
};

export const validateRequired = (attribute) => {
  if (attribute === '' || attribute.trim() === '' || attribute === null) {
    return false;
  }
  return attribute.trim();
};

export const validateIC = (txt) => {
  if (txt.length === 12) {
    const regex =
      /^((([0-9]{2})(0[13578]|1[02])(0[1-9]|[12][0-9]|3[01]))|(([2-9]{2})(0[13456789]|1[012])(0[1-9]|[12]\d|30))|(([0-9]{2})02(0[1-9]|1\d|2[0-8]))|((([02468][048]|[13579][26]))0229))([0-9]{2})([0-9]{4})$/;
    return regex.test(txt);
  }
  return false;
};

export const validateMobileWithoutCountryCode = (txt) => {
  if (txt.length === 9 || txt.length === 10) {
    const reg = /^\d{9,10}$/;
    return reg.test(txt);
  }
  return false;
};

export const validatePhone = (txt) => {
  if (txt.length >= 10 && txt.length < 13) {
    const regex = /^[6][0](\d{8,10})$/;
    return regex.test(txt);
  }
  return false;
};

export const validatePercentage = (txt) => {
  if (parseFloat(txt) >= 0 && parseFloat(txt) <= 1) {
    return true;
  }
  return false;
};

export const validateSpecialCharacter = (txt) => {
  const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return format.test(txt);
};

export const validateNumber = (txt) => {
  if (/^\d+(?:\.\d{1,2})?$/.test(txt)) {
    return true;
  }
  return false;
};

export const validateWebsite = (txt) => {
  const regex = /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&=]*)/;
  return regex.test(txt);
};

export const validateLatitude = (txt) => {
  if (isNaN(txt)) {
    return false;
  }
  if (txt < -90 || txt > 90) {
    return false;
  }
  return true;
};

export const validateLongitude = (txt) => {
  if (isNaN(txt)) {
    return false;
  }
  if (txt < -180 || txt > 180) {
    return false;
  }
  return true;
};

export const validateShortString = (txt, required = true) => {
  if (!required && !txt) return true;
  if (txt.length < 50) return true;

  return false;
};

export const validateNormalString = (txt, required = true) => {
  if (!required && !txt) return true;
  if (txt.length < 120) return true;

  return false;
};

export const validateLongString = (txt, required = true) => {
  if (!required && !txt) return true;
  if (txt.length < 250) return true;

  return false;
};

export const removeExtraSpace = (txt) => {
  // console.log('remove space:' + typeof txt + txt);
  return txt.trim();
};

export const regexNumberOnly = (txt) => /^\d+$/.test(txt);
export const isEmptyString = (txt) => (txt == null ? true : txt.trim() === '');
export const isMinLength = (txt, min) => (txt == null ? false : txt.trim().length >= min);
export const isNumber2Decimal = (num) => /^\d{0,8}(\.[0-9][0]?)?$/.test(num);
export const isNumber0Decimal = (num) => /^[0-9]{0,8}$/.test(num);
export const isValidName = (txt) => /^([a-zA-Z@_ -])*$/.test(txt);
export const isValidUsername = (txt) => /^\S*$/.test(txt); //([a-zA-Z0-9@_-/!#$%^&*+<>?]{${min},100})+
export const isValidEmail = (txt) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(txt);
};
export const isValidNric = (txt) => {
  if (txt.length !== 12) return false;
  // const regex =
  //   /^((([0-9]{2})(0[13578]|1[02])(0[1-9]|[12][0-9]|3[01]))|(([2-9]{2})(0[13456789]|1[012])(0[1-9]|[12]\d|30))|(([0-9]{2})02(0[1-9]|1\d|2[0-8]))|((([02468][048]|[13579][26]))0229))([0-9]{2})([0-9]{4})$/;
  // return regex.test(txt);

  const yyyy = '20' + txt.substr(0, 2);
  const mm = txt.substr(2, 2);
  const dd = txt.substr(4, 2);
  const date = new Date(+yyyy, +mm - 1, +dd);
  const year = date.getFullYear();
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  if (month.length === 1) month = '0' + month;
  if (day.length === 1) day = '0' + day;
  return `${year}${month}${day}` === `${yyyy}${mm}${dd}`;
};
export const isValidContactNo = (txt) => {
  // if (txt.length < 10 || txt.length > 12) return false;
  const regex = /^[6][0](\d{8,10})?$/;
  return regex.test(txt);
};
export const isValidMobileNo = (txt) => {
  const regex = /^[6][0][1](\d{8,9})$/;
  return regex.test(txt);
};
export const isValidLatitude = (txt) => {
  if (txt < -90 || txt > 90) return false;
  return /^[-]?\d{0,2}(\.\d{0,8})?$/.test(txt);
};
export const isValidLongitude = (txt) => {
  if (txt < -180 || txt > 180) return false;
  return /^[-]?\d{0,3}(\.\d{0,8})?$/.test(txt);
};
export const isValidWebsite = (txt) => {
  const regex = /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&=]*)/;
  return regex.test(txt);
};
export const isValidPostcode = (txt) => {
  if (txt.length !== 5) return false;
  return regexNumberOnly(txt);
};
