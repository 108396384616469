import packageJson from '../../package.json';

export const BANK_NAMES = [
  '',
  '08-AmBank Berhad',
  '12-Alliance Bank Berhad',
  '53-Al-Rajhi Banking and Investment Corporation (Malaysia) Berhad',
  '32-Affin Bank Berhad',
  '49-Agro Bank',
  '42-Bank of China (Malaysia) Berhad',
  '41-Bank Muamalat Malaysia Berhad',
  '45-Bank Islam Malaysia Berhad',
  '02-Bank Rakyat Berhad',
  '10-Bank Simpanan Nasional Berhad',
  '07-Bank of America',
  '52-Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad',
  '60-BNP Paribas (M) Bhd / BNP Paribas (Islamic)',
  '35-CIMB Bank Berhad',
  '17-Citibank',
  '19-Deutsche Bank (M) Bhd',
  '24-Hong Leong Bank Berhad/ Hong Leong Finance',
  '22-HSBC Bank Berhad',
  '59-Indust & Comm Bank of China (M) Berhad',
  '48-JP Morgan Chase',
  '47-Kuwait Finance House',
  '27-Malayan Banking Berhad',
  '73-Mizuho Bank (M) Berhad',
  '29-OCBC Bank Berhad',
  '33-Public Bank Berhad/ Public Finance Berhad',
  '18-RHB Bank Berhad',
  '14-Standard Chartered Bank Berhad',
  '51-Sumitomo Mitsui Banking Corporation (M) Bhd ',
  '46-The Royal Bank of Scotland Bhd',
  '26-United Overseas Bank (M) Bhd',
];

export const ACTIVELIST = ['', 'active', 'inactive'];
export const TRANSACTIONSTATUSLIST = ['', 'completed', 'cancelled'];
export const STATUSLIST = ['', 'pending', 'cancelled', 'approved', 'rejected'];
export const HOTPICKLIST = ['', 'HotPick', 'Non-HotPick'];
export const STATELIST = [
  '',
  'Johor',
  'Kedah',
  'Kelantan',
  'Melaka',
  'Negeri Sembilan',
  'Pahang',
  'Perak',
  'Perlis',
  'Pulau Pinang',
  'Sarawak',
  'Selangor',
  'Terengganu',
  'Kuala Lumpur',
  'Labuan',
  'Sabah',
  'Putrajaya',
];
export const BANKLIST = [
  '',
  'AmBank Berhad',
  'Alliance Bank Berhad',
  'Al-Rajhi Banking and Investment Corporation (Malaysia) Berhad',
  'Affin Bank Berhad',
  'Agro Bank',
  'Bank of China (Malaysia) Berhad',
  'Bank Muamalat Malaysia Berhad',
  'Bank Islam Malaysia Berhad',
  'Bank Rakyat Berhad',
  'Bank Simpanan Nasional Berhad',
  'Bank of America',
  'Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad',
  'BNP Paribas (M) Bhd / BNP Paribas (Islamic)',
  'CIMB Bank Berhad',
  'Citibank',
  'Deutsche Bank (M) Bhd',
  'Hong Leong Bank Berhad/ Hong Leong Finance',
  'HSBC Bank Berhad',
  'Indust & Comm Bank of China (M) Berhad',
  'JP Morgan Chase',
  'Kuwait Finance House',
  'Malayan Banking Berhad',
  'Mizuho Bank (M) Berhad',
  'OCBC Bank Berhad',
  'Public Bank Berhad/ Public Finance Berhad',
  'RHB Bank Berhad',
  'Standard Chartered Bank Berhad',
  'Sumitomo Mitsui Banking Corporation (M) Bhd ',
  'The Royal Bank of Scotland Bhd',
  'United Overseas Bank (M) Bhd',
];
export const STAFF_ROLELIST = ['', 'Cashier', 'Manager'];

// export const DOCUMENT_TYPELIST = [
//   'NRIC',
//   'Bank Statement',
//   'SSM',
//   'Photo',
//   'Contract',
//   'Application Form',
//   'Consent Form',
//   'Request Form',
//   'Checklist',
//   'Other',
// ];

export const ACTION = {
  create: 'create',
  read: 'read',
  update: 'update',
  delete: 'delete',
  update_amount: 'update_amount',
  sendotp: 'sendotp',
  sendmail: 'sendmail',
};

export const MODULE = {
  dashboard: 'dashboard',
  profile: 'profile',

  admin: 'admin',
  role: 'role',
  permission: 'permission',
  merchant: 'merchant',
  staff: 'staff',
  customer: 'customer',
  // transaction: 'transaction',
  sale: 'sale',
  redemption: 'redemption',
  topup: 'topup',
  withdraw: 'withdraw',
  product: 'product',
  ads: 'ads',
  landing: 'landing',
  currency: 'currency',
  version: 'version',
  report: 'report',
};

export const MERCHANT_TABS = ['Detail', 'Person-In-Charge', 'Staff', 'Document', 'Product'];
export const PACKAGE_VERSION = packageJson.version;
