import React from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ajax } from './helpers/ajax';
import {
  storeFullname,
  storeIsLogin,
  storePermissions,
  storeUserId,
  storeUsername,
} from './store/actions/user';
import Login from './pages/auth/Login';
import ModalLoading from './components/ModalLoading.js';
import Layout from './components/Layout';

const App = () => {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.app.access_token);
  const isLogin = useSelector((state) => state.user.is_login);
  const [isShow, setIsShow] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => setIsShow(false), 2000);
    if (access_token) isValidAccessToken();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValidAccessToken = () => {
    ajax({
      token: access_token,
      url: '/account/get',
      data: {},
      success: (result) => {
        dispatch(storeUserId(result.data.id));
        dispatch(storeUsername(result.data.username));
        dispatch(storeFullname(result.data.name));
        dispatch(storePermissions(result.data.permissions));
        dispatch(storeIsLogin(true));
      },
      fail: (result) => {},
      complete: () => {},
    });
  };

  return (
    <Router>
      {isLogin ? (
        <Layout />
      ) : isShow ? (
        <ModalLoading />
      ) : (
        <>
          <Redirect to={'/'} />
          <Route path={'/'} exact component={Login} />
        </>
      )}
    </Router>
  );
};
export default App;

// hello test
