import {
  STORE_IS_LOGIN,
  STORE_IS_LOADING,
  STORE_IS_ALERT,
  STORE_IS_FLOATINGMENU_HIDDEN,
  STORE_IS_DRAWER_HIDDEN,
  STORE_USERID,
  STORE_USERNAME,
  STORE_FULLNAME,
  STORE_PERMISSIONS,
} from '../actions/user';

const initialState = {
  is_login: false,
  is_loading: false,
  is_alert: false,
  is_floatingmenu_hidden: true,
  is_drawer_hidden: false,
  userid: -1,
  username: '',
  fullname: '',
  permissions: [],
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case STORE_IS_LOGIN:
      return { ...state, is_login: action.is_login };
    case STORE_IS_LOADING:
      return { ...state, is_loading: action.is_loading };
    case STORE_IS_ALERT:
      return { ...state, is_alert: action.is_alert };
    case STORE_IS_FLOATINGMENU_HIDDEN:
      return { ...state, is_floatingmenu_hidden: action.is_floatingmenu_hidden };
    case STORE_IS_DRAWER_HIDDEN:
      return { ...state, is_drawer_hidden: action.is_drawer_hidden };
    case STORE_USERID:
      return { ...state, userid: action.userid };
    case STORE_USERNAME:
      return { ...state, username: action.username };
    case STORE_FULLNAME:
      return { ...state, fullname: action.fullname };
    case STORE_PERMISSIONS:
      return { ...state, permissions: action.permissions };
    default:
      return state;
  }
}
export default userReducer;
