import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import routes from '../routes/sidebar';
import * as Icons from '../icons';
import { storeIsFloatingMenuHidden } from '../store/actions/user';
import Logo from '../assets/img/logo.png';
import { ACTION } from '../helpers/Constants';
import { HasActionPermission } from '../helpers/permission';

export const Drawer = () => {
  const history = useHistory();

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <div className={'bg-appColor1 cursor-pointer'} onClick={() => history.push('/')}>
        <img src={Logo} className={'m-auto w-1/2'} alt={'logo'} />
      </div>

      <Menu />
    </>
  );
};

export const Menu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.user.permissions);

  const navigateHandler = (path) => {
    dispatch(storeIsFloatingMenuHidden(true));
    history.push(path);
  };
  const Icon = ({ icon, ...props }) => {
    const Icon = Icons[icon];
    return <Icon {...props} />;
  };

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <ul className={`pt-4 space-y-2`}>
        {routes.map((route) =>
          route.routes ? (
            // submenu
            <SubMenu route={route} key={route.name} />
          ) : // menu
          HasActionPermission(permissions, route.module, ACTION.read) ? (
            <li
              // <NavLink exact to={route.path} activeClassName={'text-green-400 font-bold'}
              className={
                'justify-between items-center w-full px-4 py-2 text-sm font-semibold transition-all text-gray-900 hover:text-hover1 active:text-appColor1 outline-none cursor-pointer'
              }
              key={route.name}
              onClick={navigateHandler.bind(this, route.path)}>
              <div className={'inline-flex items-center'}>
                <Icon className={'w-5 h-5'} aria-hidden={'true'} icon={route.icon} />
                <span className={'ml-4'}>{route.name}</span>
              </div>
            </li>
          ) : null,
        )}
      </ul>
    </>
  );
};

export const SubMenu = ({ route }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.user.permissions);
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = React.useState(false);
  const [visibleRoute, setVisibleRoute] = React.useState([]);

  const toggleDropdownHandler = () => setIsDropdownMenuOpen(!isDropdownMenuOpen);
  const navigateHandler = (path) => {
    dispatch(storeIsFloatingMenuHidden(true));
    history.push(path);
  };
  const Icon = ({ icon, ...props }) => {
    const Icon = Icons[icon];
    return <Icon {...props} />;
  };

  React.useEffect(() => {
    setVisibleRoute(
      route.routes.filter((r) =>
        HasActionPermission(permissions, r.module, ACTION.read) ? r : null,
      ),
    );
    return () => {};
  }, []);

  return (
    <>
      {visibleRoute.length === 0 ? null : (
        <div className={'px-4 py-2'} key={route.name}>
          <div
            className={
              'inline-flex items-center justify-between w-full text-sm font-semibold transition-all text-gray-900 hover:text-hover1 active:text-appColor1 outline-none cursor-pointer'
            }
            onClick={toggleDropdownHandler}
            aria-haspopup={'true'}>
            <div className={'inline-flex items-center'}>
              <Icon className={'w-5 h-5'} aria-hidden={'true'} icon={route.icon} />
              <span className={'ml-4'}>{route.name}</span>
            </div>
            <Icon className={'w-4 h-4'} aria-hidden={'true'} icon={'DropdownIcon'} />
          </div>

          {isDropdownMenuOpen && (
            <ul
              className={
                'items-center justify-between w-full text-sm font-semibold transition-all text-gray-900 hover:text-hover1 active:text-appColor1 outline-none cursor-pointer rounded-md shadow-inner bg-gray-50 mt-2 p-2 space-y-2'
              }
              aria-label={'submenu'}>
              {visibleRoute.map((r) => (
                <li
                  // <NavLink exact to={r.path} activeClassName={'text-green-400 font-bold'}
                  className={
                    'block px-2 py-1 transition-colors duration-150 text-gray-900 hover:text-hover1'
                  }
                  key={r.name}
                  onClick={navigateHandler.bind(this, r.path)}>
                  <div className={'inline-flex items-center'}>
                    <span className={'ml-4'}>{r.name}</span>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
};
