import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { storeIsFloatingMenuHidden } from '../store/actions/user';
import ThemedSuspense from './ThemedSuspense';
import routes from '../routes';
import Header from './Header';
import { Drawer, Menu } from './Drawer';
import { Portal } from '@headlessui/react';
import { getPage404, HasActionPermission } from '../helpers/permission';
import { ACTION } from '../helpers/Constants';
// import Portal from '../components/Portal';
const Page404 = React.lazy(() => import('../pages/404'));

const Layout = () => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.user.permissions);
  const isFloatingMenuHidden = useSelector((state) => state.user.is_floatingmenu_hidden);
  const isDrawerHidden = useSelector((state) => state.user.is_drawer_hidden);

  const toggleFloatingMenu = () => dispatch(storeIsFloatingMenuHidden(true));

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className={'w-screen h-screen inline-flex overflow-hidden'}>
      <div
        className={`bg-white min-w-[13rem] max-w-xs h-screen shadow-md overflow-y-auto ${
          isDrawerHidden ? 'hidden' : 'hidden sm:block sm:w-1/4'
        }`}>
        <Drawer />
      </div>

      {isFloatingMenuHidden ? null : (
        <Portal>
          <div
            className={`fixed inset-0 z-100 bg-gray-600 bg-opacity-75 sm:hidden`}
            onClick={toggleFloatingMenu}>
            <aside
              className={`w-3/5 h-4/5 mx-auto my-16 overflow-y-auto bg-white rounded-lg overflow-hidden`}
              onClick={(e) => e.stopPropagation()}>
              <Menu />
            </aside>
          </div>
        </Portal>
      )}

      <div className={`w-full`}>
        <Header />
        <main className={'h-full m-auto p-4 overflow-y-auto bg-gray-100'} id={'ken-content'}>
          <Suspense fallback={<ThemedSuspense />}>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={true}
                  render={(props) => {
                    return HasActionPermission(permissions, route.module, ACTION.read) ? (
                      <route.component {...props} />
                    ) : (
                      getPage404()
                    );
                  }}
                />
              ))}
              <Route component={Page404} />
            </Switch>
          </Suspense>
        </main>
      </div>
    </div>
  );
};

export default Layout;
