import { MODULE } from '../helpers/Constants';

/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
const routes = [
  { path: '/', icon: 'HomeIcon', name: 'Dashboard', module: MODULE.dashboard },
  {
    icon: 'PeopleIcon',
    name: 'Admin',
    routes: [
      // submenu
      { path: '/admin', name: 'Admin', module: MODULE.admin },
      { path: '/role', name: 'Role', module: MODULE.role },
      { path: '/permission', name: 'Permission', module: MODULE.permission },
    ],
  },
  { path: '/merchant', icon: 'PeopleIcon', name: 'Merchant', module: MODULE.merchant },
  { path: '/user', icon: 'PeopleIcon', name: 'User', module: MODULE.customer },
  {
    icon: 'PagesIcon',
    name: 'Transaction',
    routes: [
      // submenu
      { path: '/transaction', name: 'Transaction', module: MODULE.sale },
      { path: '/redemption', name: 'Redemption', module: MODULE.redemption },
      { path: '/topUp', name: 'Top Up', module: MODULE.topup },
      { path: '/withdrawal', name: 'Withdrawal', module: MODULE.withdraw },
    ],
  },
  { path: '/product', icon: 'CartIcon', name: 'Product', module: MODULE.product },
  {
    icon: 'PagesIcon',
    name: 'App Config',
    routes: [
      // submenu
      { path: '/version-control', name: 'App Version Control', module: MODULE.version },
      { path: '/advertisement', name: 'Advertisement', module: MODULE.ads },
      { path: '/generate-currency', name: 'Generate Currency', module: MODULE.currency },
    ],
  },
  // { path: '/report', icon: 'PagesIcon', name: 'Reports', module: MODULE.report },
  {
    icon: 'PagesIcon',
    name: 'Reports',
    routes: [
      // submenu
      { path: '/transaction-report', name: 'Transaction Report', module: MODULE.report },
      {
        path: '/custome-daily-reward-report',
        name: 'Customer Daily Reward Report',
        module: MODULE.report,
      },
    ],
  },
];

export default routes;
