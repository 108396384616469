import React from 'react';
import { Route } from 'react-router';
import { MODULE } from './Constants';

/**
 * Determine given action is valid permission or not
 * by using bitwise AND (&) operator
 *
 * example:
 * 1. (16 & 20) => 16
 * 2. ( 8 & 20) =>  0
 * 3. ( 4 & 20) =>  4
 * 4. ( 2 & 20) =>  0
 *
 * @params number value         permission value needed to do this action [create|read|update|delete]
 * @params number permission    sum of permission value for the module, max:30
 *
 * @returns bool
 */
export const checkPermissions = (value, permission) => {
  if (!value) {
    return false;
  }
  if (value & permission) {
    return true;
  }
  return false;
};

// constants :
// const create = 2;
// const read = 4;
// const update = 8;
// const delete = 16;

// usage at Product module :
// import { checkPermissions } from "../helpers/permission";
// ...
// // store role and permission value in redux during login
// const permission = admin.permission; // get from redux
// ...

// const addProductHandler = () => {
//   if (!checkPermissions(create, permission.product)) return;
//
//   add product ...
// };

const Page404 = React.lazy(() => import('../pages/404'));

export const isSuperAdmin = (id) => id === 1;
export const isOwner = (storeUserId, dataUserId) => storeUserId === dataUserId;
export const HasActionPermission = (permissions, module, action) => {
  if (module === MODULE.dashboard) return true;
  if (module === MODULE.profile) return true;

  if (!(module in permissions)) return false;
  if (permissions[module] == null) return false;
  if (!permissions[module].includes(action)) return false;
  return true;
};
export const getPage404 = () => <Route component={Page404} />;
