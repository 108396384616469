import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import appReducer from './reducers/app';
import userReducer from './reducers/user';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['app'],
};
const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
});
const store = createStore(persistReducer(rootPersistConfig, rootReducer));
const persistor = persistStore(store);
export { store, persistor };
