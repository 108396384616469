import React from 'react';
import { useDispatch } from 'react-redux';
import { ajax } from '../../helpers/ajax';
import ImageLight from '../../assets/img/logo.png';
import Swal from 'sweetalert2';
import { removeExtraSpace } from '../../helpers/validation';
import {
  storeIsLogin,
  storeFullname,
  storeUsername,
  storeUserId,
  storePermissions,
} from '../../store/actions/user';
import { storeAccessToken } from '../../store/actions/app';
import { PACKAGE_VERSION } from '../../helpers/Constants';

const Login = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const loginHandler = () => {
    if (!(username.trim() && password.trim())) {
      Swal.fire({
        icon: 'error',
        title: 'Username and Password field must not empty',
        showConfirmButton: false,
        timer: 30000,
      });
      return;
    }

    callAjax();
  };

  const callAjax = () => {
    setIsLoading(true);
    ajax({
      url: '/oauth/token',
      data: {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        grant_type: 'password',
        username: removeExtraSpace(username),
        password: removeExtraSpace(password),
      },
      success: (result) => getUserInformation(result.access_token),
      fail: (result) =>
        Swal.fire({
          icon: 'error',
          title: result.message,
          showConfirmButton: false,
          timer: 30000,
        }),
      complete: () => setIsLoading(false),
    });
  };

  const getUserInformation = (access_token) => {
    setIsLoading(true);
    ajax({
      token: access_token,
      url: '/account/update/onlogin',
      data: {},
      success: (result) => {
        dispatch(storeUserId(result.data.id));
        dispatch(storeUsername(result.data.username));
        dispatch(storeFullname(result.data.name));
        dispatch(storePermissions(result.data.permissions));
        dispatch(storeAccessToken(access_token));
        dispatch(storeIsLogin(true));
      },
      fail: (result) =>
        Swal.fire({
          icon: 'error',
          title: result.message,
          showConfirmButton: false,
          timer: 30000,
        }),
      complete: () => setIsLoading(false),
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="h-full w-full md:object-center md:h-full md:w-60"
            src={ImageLight}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Admin Login</h2>
        </div>
        <form className="mt-8 space-y-6">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Username
              </label>
              <input
                type="text"
                placeholder={'Enter your username'}
                value={username}
                onChange={(text) => setUsername(text.target.value.trim())}
                onKeyPress={(e) => e.key === 'Enter' && loginHandler()}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                type="password"
                placeholder={'Enter your password'}
                value={password}
                onChange={(text) => setPassword(text.target.value.trim())}
                onKeyPress={(e) => e.key === 'Enter' && loginHandler()}
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              />
            </div>
          </div>

          <div>
            {isLoading && <div>Loading...</div>}
            <button
              style={{ backgroundColor: '#14B19E' }}
              type="button"
              disabled={isLoading}
              onClick={loginHandler}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Log in
            </button>
          </div>
          <p className={'text-xs text-gray-900 text-center'}>{`Version: ${PACKAGE_VERSION}`}</p>
        </form>
      </div>
      {/* <div className={'absolute bottom-0 right-0 bg-yellow-100'}>
        <h2 className={''}>{`Last update: ${process.env.REACT_APP_UPDATE}`}</h2>
        <h2 className={''}>{`Version: ${process.env.NODE_ENV}-${process.env.REACT_APP_VERSION}`}</h2>
      </div> */}
    </div>
  );
};

export default Login;
