import { lazy } from 'react';
import { MODULE } from '../helpers/Constants';

const Dashboard = lazy(() => import('../pages/Dashboard'));
const Profile = lazy(() => import('../pages/admin/Profile'));
const Admin = lazy(() => import('../pages/admin/Admin'));
const Role = lazy(() => import('../pages/admin/Role'));
const Permission = lazy(() => import('../pages/admin/Permission'));
// const User = lazy(() => import('../pages/customer/User'));
const User = lazy(() => import('../pages/customer/User-copy'));
const Merchant = lazy(() => import('../pages/merchantv2/Merchant'));
const MerchantAdd = lazy(() => import('../pages/merchantv2/AddMerchant'));
const MerchantTab = lazy(() => import('../pages/merchantv2/Tab'));
// const Product = lazy(() => import('../pages/product/Product'));
const Product = lazy(() => import('../pages/product/Product-copy'));
// const ProductDetail = lazy(() => import('../pages/product/ProductDetail'));
const ProductDetail = lazy(() => import('../pages/product/ProductDetail-copy'));
// const AddProduct = lazy(() => import('../pages/product/AddProduct'));
const AddProduct = lazy(() => import('../pages/product/AddProduct-copy'));
// const ProductMerchantList = lazy(() => import('../pages/product/ProductMerchantList'));
const ProductMerchantList = lazy(() => import('../pages/product/ProductMerchantList-copy'));
// const Transaction = lazy(() => import('../pages/transaction/Transaction'));
const Transaction = lazy(() => import('../pages/transaction/Transaction-copy'));
// const TransactionDetail = lazy(() => import('../pages/transaction/TransactionDetail'));
const TransactionDetail = lazy(() => import('../pages/transaction/TransactionDetail-copy'));
// const Redemption = lazy(() => import('../pages/transaction/Redemption'));
const Redemption = lazy(() => import('../pages/transaction/Redemption-copy'));
// const RedemptionDetail = lazy(() => import('../pages/transaction/RedemptionDetail'));
const RedemptionDetail = lazy(() => import('../pages/transaction/RedemptionDetail-copy'));
// const TopUp = lazy(() => import('../pages/transaction/TopUp'));
const TopUp = lazy(() => import('../pages/transaction/TopUp-copy'));
// const TopUpDetail = lazy(() => import('../pages/transaction/TopUpDetail'));
const TopUpDetail = lazy(() => import('../pages/transaction/TopUpDetail-copy'));
// const Withdrawal = lazy(() => import('../pages/transaction/Withdrawal'));
const Withdrawal = lazy(() => import('../pages/transaction/Withdrawal-copy'));
const WithdrawalDetail = lazy(() => import('../pages/transaction/WithdrawalDetail'));
const AppVersionControl = lazy(() => import('../pages/settings/AppVersionControl'));
const Advertisement = lazy(() => import('../pages/settings/Advertisement'));
const GenerateCurrency = lazy(() => import('../pages/settings/GenerateCurrency'));
const Report = lazy(() => import('../pages/Report'));
const TransactionReport = lazy(() => import('../pages/report/TransactionReport'));
const CustomerDailyRewardReport = lazy(() => import('../pages/report/CustomerDailyRewardReport'));
const routes = [
  { path: '/', component: Dashboard, module: MODULE.dashboard },
  { path: '/profile', component: Profile, module: MODULE.profile },
  { path: '/admin', component: Admin, module: MODULE.admin },
  { path: '/role', component: Role, module: MODULE.role },
  { path: '/permission', component: Permission, module: MODULE.permission },
  { path: '/user', component: User, module: MODULE.customer },
  { path: '/merchant', component: Merchant, module: MODULE.merchant },
  { path: '/merchant-add', component: MerchantAdd, module: MODULE.merchant },
  { path: '/merchant-info', component: MerchantTab, module: MODULE.merchant },

  { path: '/Product', component: Product, module: MODULE.product },
  { path: '/ProductDetail', component: ProductDetail, module: MODULE.product },
  { path: '/AddProduct', component: AddProduct, module: MODULE.product },
  { path: '/ProductMerchantList', component: ProductMerchantList, module: MODULE.product },
  // { path: '/product', component: Product, module: MODULE.product },
  // { path: '/product-detail', component: ProductDetail, module: MODULE.product },
  // { path: '/product-add', component: AddProduct, module: MODULE.product },
  // { path: '/product-merchant', component: ProductMerchantList, module: MODULE.product },

  { path: '/Transaction', component: Transaction, module: MODULE.sale },
  { path: '/TransactionDetail', component: TransactionDetail, module: MODULE.sale },
  { path: '/Redemption', component: Redemption, module: MODULE.redemption },
  { path: '/RedemptionDetail', component: RedemptionDetail, module: MODULE.redemption },
  { path: '/TopUp', component: TopUp, module: MODULE.topup },
  { path: '/TopUpDetail', component: TopUpDetail, module: MODULE.topup },
  { path: '/Withdrawal', component: Withdrawal, module: MODULE.withdraw },
  { path: '/WithdrawalDetail', component: WithdrawalDetail, module: MODULE.withdraw },
  // { path: '/transaction', component: Transaction, module: MODULE.sale },
  // { path: '/transaction-detail', component: TransactionDetail, module: MODULE.sale },
  // { path: '/redemption', component: Redemption, module: MODULE.redemption },
  // { path: '/redemption-detail', component: RedemptionDetail, module: MODULE.redemption },
  // { path: '/topup', component: TopUp, module: MODULE.topup },
  // { path: '/topup-detail', component: TopUpDetail, module: MODULE.topup },
  // { path: '/withdrawal', component: Withdrawal, module: MODULE.withdraw },
  // { path: '/withdrawal-detail', component: WithdrawalDetail, module: MODULE.withdraw },

  { path: '/version-control', component: AppVersionControl, module: MODULE.version },
  { path: '/Advertisement', component: Advertisement, module: MODULE.ads },
  // { path: '/advertisement', component: Advertisement, module: MODULE.ads },
  { path: '/generate-currency', component: GenerateCurrency, module: MODULE.currency },

  {
    path: '/custome-daily-reward-report',
    component: CustomerDailyRewardReport,
    module: MODULE.report,
  },
  { path: '/transaction-report', component: TransactionReport, module: MODULE.report },
];

export default routes;
