import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { storeAccessToken } from '../store/actions/app';
import {
  storeIsLogin,
  storeIsDrawerHidden,
  storeIsFloatingMenuHidden,
} from '../store/actions/user';
import { ajax } from '../helpers/ajax';
import LongButton from './LongButton';
import { FaBars, FaSignOutAlt, FaUser } from 'react-icons/fa';

const Header = () => {
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state.app.access_token);
  const isFloatingMenuHidden = useSelector((state) => state.user.is_floatingmenu_hidden);
  const isDrawerHidden = useSelector((state) => state.user.is_drawer_hidden);
  const fullname = useSelector((state) => state.user.fullname);
  const history = useHistory();

  // tagged template literals
  const titleWelcome = (text, ...values) => {
    return values.reduce((final, value, index) => {
      return `${final}${value}${text[index + 1]}`;
    }, text[0]);
  };
  const toggleFloatingMenu = () => dispatch(storeIsFloatingMenuHidden(!isFloatingMenuHidden));
  const toggleDrawer = () => dispatch(storeIsDrawerHidden(!isDrawerHidden));
  const navigateProfile = () => history.push('/profile');
  const logoutHandler = () => {
    ajax({
      token: access_token,
      url: '/account/logout',
      data: {},
      success: (result) => {
        dispatch(storeAccessToken(''));
        dispatch(storeIsLogin(false));
      },
      fail: (result) => {},
      complete: () => {},
    });
  };

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <header className={'py-4 shadow-md bg-white border-appColor1'}>
      <div className={'justify-between items-center h-full w-full px-4 text-gray-600 bg-white'}>
        <div className={'sm:hidden inline-flex justify-start items-center w-3/5'}>
          <LongButton
            className={'bg-gray-100 hover:bg-gray-300 hover:ring-2 hover:ring-appColor1'}
            onTap={toggleFloatingMenu}
            buttonType={'submit'}
            name={'menu'}>
            <FaBars className={'w-3 h-3 text-appColor1'} aria-hidden={true} />
          </LongButton>
        </div>

        <div className={'hidden sm:inline-flex justify-start items-center w-3/5'}>
          <LongButton
            className={'bg-gray-100 hover:bg-gray-300 hover:ring-2 hover:ring-appColor1'}
            onTap={toggleDrawer}
            buttonType={'submit'}
            name={'menu'}>
            <FaBars className={'w-3 h-3 text-appColor1'} aria-hidden={true} />
            <div className={'hidden sm:block text-sm text-appColor1 text-top px-2'}>{`Menu`}</div>
          </LongButton>

          <div className={'hidden lg:block lg:px-4 truncate'}>
            <span
              className={'text-sm font-semibold'}>{titleWelcome`Welcome back, ${fullname}`}</span>
          </div>
        </div>

        <div className={'inline-flex justify-end pr-4 space-x-4 w-2/5'}>
          <div className={''}>
            <LongButton
              className={'bg-gray-100 hover:bg-gray-300 hover:ring-2 hover:ring-appColor1'}
              onTap={navigateProfile}
              buttonType={'submit'}
              name={'profile'}>
              <FaUser className={'w-3 h-3 text-appColor1'} />
              <div
                className={'hidden sm:block text-sm text-appColor1 text-top px-2'}>{`Profile`}</div>
            </LongButton>
          </div>
          <div className={''}>
            <LongButton
              className={'bg-gray-100 hover:bg-gray-300 hover:ring-2 hover:ring-appColor1'}
              onTap={logoutHandler}
              buttonType={'submit'}
              name={'logout'}>
              <FaSignOutAlt className={'w-3 h-3 text-appColor1'} />
              <div
                className={'hidden sm:block text-sm text-appColor1 text-top px-2'}>{`Logout`}</div>
            </LongButton>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
